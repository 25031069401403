import React, { useState } from 'react';
import mapImage1 from './Pictures/Maps/1k.png';
import mapImage2 from './Pictures/Maps/2k.png';
import mapImage3 from './Pictures/Maps/3k.png';
import RandomLocation from './Randomizer';
import Homepage from './Homepage.css'

function GuessGame({ currentMap, element, showPrompt, setShowPrompt, refreshElement }) {
    const [guess, setGuess] = useState({ x: null, y: null });
    const actualPoint = { x: element.posx, y: element.posy };
    const floor = element.floor;
    const [result, setResult] = useState('');
    const mapImages = [mapImage1, mapImage2, mapImage3];

    const handleClick = (e) => {
      if (guess.x !== null) return;
    
      const rect = e.target.getBoundingClientRect();
      const clickX = e.clientX - rect.left; 
      const clickY = e.clientY - rect.top;
    
      setGuess({ x: clickX, y: clickY });

      actualPoint.x = (actualPoint.x / 592) * 100;
      actualPoint.y = (actualPoint.y / 837) * 100;
    
      setResult(GetResult(clickX, clickY, rect));
      setShowPrompt(true);
      
      window.scrollTo({
        top: 0
      });
    };
    

    const closePrompt = () => {
      setShowPrompt(false);
      setGuess({ x: null, y: null });
      refreshElement();
    };

    const calculateDistance = (xGuess, yGuess, rect) => {
      
      const xGuessPercent = (xGuess / rect.width) * 100;
      const yGuessPercent = (yGuess / rect.height) * 100;

      return Math.round(Math.sqrt((xGuessPercent - actualPoint.x) ** 2 + (yGuessPercent - actualPoint.y) ** 2) * 0.44);
    };
    

    const isCorrectFloor = () => {
        return floor - 1 === currentMap;
    };

    const PromptBox = () => (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        backgroundColor: '#212121', 
        padding: '20px', 
        zIndex: 1000,
        borderRadius: '10px' /* You can adjust the value as needed */
      }}>
        <p>{result}</p>
        <button onClick={closePrompt} class="button-64" role="button"><span class="text">Järgmine</span></button>
      </div>
    );

    function GetResult(x, y, rect) {
      let correctFloor = isCorrectFloor();
    
      if (correctFloor) {
        let praise = "";
        let distance = calculateDistance(x, y, rect);
    
        if (distance < 1.5) {
          praise = "Võrratu! Kas sul on kaart või lihtsalt GPS sisse ehitatud?";
        }
        else if (distance < 3) {
          praise = "Nii lähedal! Oled kindel, et sa pole siin varem luuret teinud?";
        }
        else if (distance < 7) {
          praise = "Peaaegu pihtas! Kas suudad ka silmad kinni sama hästi arvata?";
        }
        else if (distance < 15) {
          praise = "Tuleb rohkem koolis käia...";
        }
        else if (distance < 25) {
          praise = "Natuke mööda, aga ära muretse, geograafia pole kõigile.";
        }
        else {
          praise = "Kas sa üritasid leida Viimsi Gümnaasiumi või Bermuda kolmnurka? Kasuta kaarti järgmine kord!";
        }
    
        return "Kaugus õigest asukohast " + distance + " meetrit. " + praise;
      }
      else {
        return "Vale korrus! Proovi uuesti, kui oled liftist välja astunud.";
      }
    }    

    return (
      <div className="page-container">
      {showPrompt && <PromptBox />}
      <div>
        <img src={mapImages[currentMap]} alt="Building Plan" onClick={handleClick} />
      </div>
    </div>
    );
}

export default GuessGame;