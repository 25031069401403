import React, { Component } from 'react';

  export const pictureDictionary = [
    { name: 'https://files.tryhard.cloud/s/fJdpKHX6bBQtyNy/preview', enabled: true, floor: 1, posx: 230, posy: 470 },
    { name: 'https://files.tryhard.cloud/s/JpCP8bxt7i5an9y/preview', enabled: true, floor: 1, posx: 200, posy: 340 },
    { name: 'https://files.tryhard.cloud/s/znckX3GBgdAZZSP/preview', enabled: true, floor: 1, posx: 280, posy: 290 },
    { name: 'https://files.tryhard.cloud/s/4M3BFJDQ9Ay463H/preview', enabled: true, floor: 1, posx: 460, posy: 575 },
    { name: 'https://files.tryhard.cloud/s/Rjtcg6HZzoKZFDn/preview', enabled: true, floor: 1, posx: 540, posy: 470 },
    { name: 'https://files.tryhard.cloud/s/NoEHCSeA3CRkHks/preview', enabled: true, floor: 1, posx: 510, posy: 470 },

    { name: 'https://files.tryhard.cloud/s/RpkJXLPjHA3rbeS/preview', enabled: true, floor: 2, posx: 165, posy: 555 },
    { name: 'https://files.tryhard.cloud/s/8Y5yNfNZ4pBogbM/preview', enabled: true, floor: 2, posx: 135, posy: 230 },
    { name: 'https://files.tryhard.cloud/s/YmsZRP5iosAEpLN/preview', enabled: true, floor: 2, posx: 320, posy: 215 },
    { name: 'https://files.tryhard.cloud/s/YGPKyN8aix5JASg/preview', enabled: true, floor: 2, posx: 436, posy: 552 },
    { name: 'https://files.tryhard.cloud/s/CkK3cYwYXJtoSDT/preview', enabled: true, floor: 2, posx: 350, posy: 550 },
    
    { name: 'https://files.tryhard.cloud/s/KrrZBBgZ3WsEkfK/preview', enabled: true, floor: 3, posx: 440, posy: 592 },
    { name: 'https://files.tryhard.cloud/s/ztjWGFQYd258d2m/preview', enabled: true, floor: 3, posx: 180, posy: 580 },
    { name: 'https://files.tryhard.cloud/s/fgcpbekZGa8rsom/preview', enabled: true, floor: 3, posx: 135, posy: 350 },
    { name: 'https://files.tryhard.cloud/s/m2CPMrqB42XcFkx/preview', enabled: true, floor: 3, posx: 190, posy: 290 },
    { name: 'https://files.tryhard.cloud/s/jLMQNHaRyzXNkD9/preview', enabled: true, floor: 3, posx: 322, posy: 205 },
  ];
  
  class ImageLoader extends Component {
    render() {
      return null;
    }
  }
  
  export default ImageLoader;