import vgmLogo from './Pictures/Logo/vgm.png';

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Play from './Play';
import './Homepage.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className='App'>
              <img
                src={vgmLogo}
                alt="VGMLogo"
                className="logo"
                />
              <div className='playbox'>
                <h1 className="title">VGMGUESSER</h1>
                <div className="game">
                  <Link to="/play">
                    <button className="button-65" role="button"><span className="text">START</span></button>
                  </Link>
                </div>
                <p className="mission">Leidke kaardil pildistaja asukoht.</p>
              </div>
            </div>
          }
        />
        <Route path="/play" element={<Play />} />
      </Routes>
    </Router>
  );
};

export default App;