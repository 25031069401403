import ImageLoader, { pictureDictionary } from './ImageLoader.js';
import './App.css';

let guesses = 0;

export function RandomLocation(force = false) {
    if (force || guesses >= pictureDictionary.length) {
      console.log("Randing");
        let lastElement = pictureDictionary[pictureDictionary.length - 1];
        shuffleArray(pictureDictionary);
        if (pictureDictionary[0] === lastElement) {
            let swapIndex = pictureDictionary.length > 1 ? 1 : 0;
            [pictureDictionary[0], pictureDictionary[swapIndex]] = [pictureDictionary[swapIndex], pictureDictionary[0]];
        }
        guesses = 0;
    }

    const element = pictureDictionary[guesses++];
    return element;
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

export default RandomLocation;