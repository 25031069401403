import vgmLogo from './Pictures/Logo/vgm.png';
import React, { useState, useEffect } from 'react';
import './App.css';
import GuessGame from './Map';
import { RandomLocation } from './Randomizer';
import BarLoader from 'react-spinners/BarLoader';
import Homepage from './Homepage.css'
import { pictureDictionary } from './ImageLoader';

const Play = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)

    pictureDictionary.forEach(elm => {
      const guessImage = new Image();
      guessImage.src = elm.name;
    });
    
    setTimeout(() => {
      setElement(RandomLocation(true));
      setLoading(false);
    }, 2000)
  }, [])
  
  const [currentMap, setCurrentMap] = useState(0);
  const [element, setElement] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    setElement(RandomLocation());
  }, [trigger]);

  const setMap = (offset) => {
    if (showPrompt) return;
    setCurrentMap(offset - 1);

    window.scrollTo({
      top: 1000
    });
  };

  const refreshElement = () => {
    setTrigger(prev => prev + 1);
  };

  if (element === null) {
    return <p>Loading...</p>;
  }

  return (
    <div className='Play'>
       <img
          src={vgmLogo}
          alt="VGMLogo"
          className="logo"
        />
      {loading ? (
        <div className='loader'>
          <div>
            <h4 className='loading-text'>Loading . . .</h4>
          </div>
          <BarLoader
            color={"#005585"}
            loading={loading}
            width={230}
            height={8}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <img
            src={vgmLogo}
            alt="VGMLogo"
            className="logo"
          />
          <header>
            <b>Kus asub see koht VGMis?</b>
            <div>
              <img src={element.name} alt="GuessImg"/>
            </div>
          </header>
        
          <button onClick={() => setMap(1)} class="button-63" role="button">
            1. KORRUS
          </button>

          <button onClick={() => setMap(2)} class="button-63" role="button">
            2. KORRUS
          </button>

          <button onClick={() => setMap(3)} class="button-63" role="button">
            3. KORRUS
          </button>
      
          <GuessGame currentMap={currentMap} element={element} showPrompt={showPrompt} setShowPrompt={setShowPrompt} refreshElement={refreshElement} />
        </>
      )}
    </div>
  );
}

export default Play;